import { useTranslations } from 'next-intl';
import Image from 'next/image';
import UNHCRLogo from '@svg/UNHCRLogo';
import React, { useState } from 'react';
import DonationInfoDrawer from './DonationInfoDrawer';
interface Props {
    donations_count?: number;
}
const HomeDonationBanner = ({ donations_count }: Props) => {
    const t = useTranslations();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <>
            <div
                className="relative border-[#008EC6]  border-4 w-full overflow-hidden rounded-xl cursor-pointer"
                onClick={() => setIsDrawerOpen(true)}
            >
                {/* Content */}
                <div className="text-[#4F2F18]">
                    <div className="relative px-4 py-6">
                        <h2 className="md:text-4xl sm:text-3xl text-2xl font-extrabold mb-2 uppercase">
                            {t('Skip the wrap & Dabdoob will contribute to UNHCR')}
                        </h2>
                        <p className="md:text-lg mb-2 text-[#7B5E4C]">
                            {t('Help contribute to UNHCR a fixed amount to support refugee children in need this Ramadan')}
                        </p>

                    </div>
                    <div className="flex justify-between">
                        <div className="flex items-center">
                            <div className="h-[119px] w-[119px] scale-90 mx-8">
                                <Image
                                    src="/imgs/dabdoob-unpacking-gift.webp"
                                    alt="dabdoob-unpacking-gift"
                                    width={119}
                                    height={119}
                                    priority
                                />
                            </div>
                            <div className="flex flex-col gap-4">
                                <h2 className="text-lg sm:text-2xl md:text-3xl font-extrabold uppercase">
                                    {t('unwrapped toys')}
                                </h2>
                                <h2 className="text-2xl md:text-4xl font-extrabold mb-2 uppercase text-[#008EC6]">
                                    {donations_count}
                                </h2>
                            </div>
                        </div>
                        <UNHCRLogo />
                    </div>
                </div>
            </div >

            <DonationInfoDrawer
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
            />
        </>
    );
}

export default HomeDonationBanner;