import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { useTranslations } from 'next-intl';
import Button from '@elements/button';
import LeftArrow from '@svg/LeftArrow'
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DonationInfoDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const t = useTranslations();

  return (
    <>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 backdrop-brightness-50 z-[60]"
          onClick={onClose}
        />
      </Transition>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-x-full"
        enterTo="opacity-100 translate-x-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 translate-x-full"
      >
        <div className="fixed right-0 top-0 h-full w-screen md:max-w-[643px] bg-[#732980] z-[61] text-white overflow-y-auto">
          {/* svg Background*/}
          <div className="absolute inset-0 -z-[10]" style={{
            backgroundImage: 'url(/svgs/ramadan-bg3.svg)',
            backgroundRepeat: 'repeat',
            backgroundSize: 'cover',
            opacity: .15
          }} />

          <div className="p-6 h-full flex flex-col">
            <div className="flex gap-2 items-center mb-6">
              <div onClick={onClose} className='cursor-pointer'>
                <LeftArrow />
              </div>
              <h1 className="text-lg font-bold">
                {t('How Your Donations Help')}
              </h1>
            </div>

            <section className="mb-6">
              <h2 className="text-xl font-extrabold mb-4">
                {t('Join Dabdoob in Giving This Ramadan')}
              </h2>
              <p className="font-semibold mb-4">
                {t('Skip the Wrapping Support a Child')}
              </p>
              <p className="mb-4">
                {t('Dabdoobs skip the wrap initiative lets you maximize your impact Choose not to have your gift wrapped and well contribute a fixed amount to support refugee children in need through the United Nations Refugee Agency')}
                <br />
                <br />
                {t('In Ramadan you can make your gift go even further')}
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-extrabold mb-4">
                {t('Why Your Participation Matters')}
              </h2>
              <p className="mb-4">
                {t('This Ramadan your support through Dabdoobs campaign has a direct impact on the lives of children in need  Every unwrapped gift contributes to spreading joy and kindness')}
                <br />
                <br />
                <span className="font-semibold">
                  {t('Thank You for Playing It Forward with Dabdoob')}
                </span>
              </p>
            </section>

            <section className="mb-6">
              <h2 className="text-xl font-extrabold mb-4">
                {t('HOW MUCH WERE DONATING PER UNWRAPPED TOY')}
              </h2>
              <p className="mb-1">
                {t('Kuwait = 008 KWD')}
              </p>
              <p className="mb-1">
                {t('Saudi Arabia = 1 SAR')}
              </p>
              <p className="mb-1">
                {t('United Arab Emirates = 1 AED')}
              </p>
              <p className="mb-1">
                {t('Qatar = 1 QAR')}
              </p>
              <p className="mb-1">
                {t('Bahrain = 01 BHD')}
              </p>
            </section>

            <Button
              onClick={onClose}
              className="w-full rounded-full mt-auto bg-[#F8D5F3] !text-[#732980] font-extrabold hover:text-[#732980] border-none"
            >
              {t('_Start Shopping')}
            </Button>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default DonationInfoDrawer; 