import type { PageWithLayout, PageProps, IHomeDataInput } from "@types";
import type { Meta } from "@layouts/page";
import type { GetServerSideProps } from "next";

import Page from "@layouts/page";
import Home from "@templates/home";
import { useTranslations } from "use-intl";
import { getCookie, setContext } from "@utils/Cookies";
import { getLayout as getDefaultLayout } from "@layouts/default-layout";
import { authApi, homeApi } from "gate";
import { hasArea, setCountryAndArea } from "@utils/checkDependencies";
import { parse } from "next-useragent";
import { COOKIE_ACCESS_TOKEN_KEY } from "@utils/constants";
import { checkIfMaintenanceNeededOrFail } from "@utils/api";

interface Props extends PageProps {
  data?: IHomeDataInput;
}

const HomePage: PageWithLayout<Props> = ({ data, appFallback }) => {
  const t = useTranslations("");
  const meta: Meta = {
    title: t("w-homePageTitle"),
    description: appFallback?.website.description || "Dabdoob",
    keywords: appFallback?.website.keywords,
  };
  return (
    <Page meta={meta}>
      <Home data={data} />
    </Page>
  );
};

HomePage.translationFile = "translation";
HomePage.getLayout = (page, props) => getDefaultLayout(page, { ...props }, {});

export const getServerSideProps: GetServerSideProps<Props> = async (ctx) => {
  const { query } = ctx;
  setContext(ctx);
  const ssr = typeof query.ssr === "string" ? query.ssr : "";
  const ua = parse(ctx.req.headers["user-agent"] || "");

  const Cookies = getCookie();

  if (!Cookies.get(COOKIE_ACCESS_TOKEN_KEY)) {
    await authApi
      .deviceAuth()
      .fetch()
      .then((res) => {
        const token = res.data.access_token || "";
        Cookies.set(COOKIE_ACCESS_TOKEN_KEY, token, {}, 365 * 2);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (ssr === "1" || ua.isBot) {
    await setCountryAndArea(ctx.locale);
  }

  if (hasArea()) {
    try {
      const data = await homeApi.getData().fetch();
      return {
        props: {
          data: data.data,
        },
      };
    } catch (error:any) {
      return checkIfMaintenanceNeededOrFail<Props>(error);
    }
  } else {
    return {
      props: {},
    };
  }
};

export default HomePage;
