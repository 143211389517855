import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const RightChevron: React.FC<Props> = ({ stroke = "#BABABA", ...props }) => {
    return (
        <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.00005 1C1.00005 1 7 5.4189 7 7C7 8.5812 1 13 1 13"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default RightChevron;
