import { useAppContext } from "@context/app";
import { ICountryInput } from "@types";
import { useIsMount } from "@utils/helpers";
import parsePhoneNumber from "libphonenumber-js";
import { useEffect, useState } from "react";

function usePhoneInput(countryList: ICountryInput[], onChange?: (v: any) => void, default_value?: string) {
  const [value, setValue] = useState<string>(default_value||"");
  const { country } = useAppContext();
  const [selectedCountry, setSelectedCountry] = useState(country);
  const [openCountries, setOpenCountries] = useState(false);

  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      handelChangeValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);
  useEffect(() => {
    if (value && countryList) {
      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber) {
        setValue(phoneNumber.nationalNumber);
        setSelectedCountry(
          countryList.find((x) => x.dialcode.includes(phoneNumber.countryCallingCode)) ?? country
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, countryList]);
  const handelChangeValue = (text: string) => {
    setValue(text);
    onChange?.(text ? `${selectedCountry?.dialcode}${text}` : `${text}`);
  };
  const handelSelectedCountry = (c: ICountryInput) => {
    setSelectedCountry(c);
    setOpenCountries(false);
  };

  return {
    value,
    setValue,
    selectedCountry,
    setSelectedCountry,
    handelSelectedCountry,
    handelChangeValue,
    openCountries,
    setOpenCountries,
  };
}

export default usePhoneInput;
