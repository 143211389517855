import Brand from "@modules/home/brand";
import Carousel from "@modules/home/carousel";
import Categories from "@modules/home/categories";
import Widget from "@modules/home/widget";
import CollectionList from "@modules/home/collectionList";
import { IHomeDataInput } from "@types";
import { useAppContext } from "@context/app";
import { useEffect, useState } from "react";
import VideoList from "@modules/home/videoList";
import dynamic from "next/dynamic";
import HomeDonationBanner from "@modules/donation/HomeDonationBanner";
import GiftSenderBanner from "@modules/gift/GiftSenderBanner";
import GiftSenderDrawer from "@modules/gift/GiftSenderDrawer";
import GiftRecipientBanner from "@modules/gift/GiftRecipientBanner";
import { useRouter } from "next/router";
const ActiveOrder = dynamic(() => import("@modules/home/activeOrder"), { ssr: false });
interface Props {
  data?: IHomeDataInput;
}

const Home: React.FC<Props> = ({ data }) => {
  const { setEventProperties, user, syncData } = useAppContext();
  const router = useRouter();

  // Check if send_gift parameter exists in the URL
  const hasSendGiftParam = !!router.query.send_gift;

  // Set initial drawer state based on send_gift parameter
  const [isGiftDrawerOpen, setIsGiftDrawerOpen] = useState(hasSendGiftParam);

  useEffect(() => {
    setEventProperties({
      from_page: "homepage",
      from_entity: "home",
      from_entity_slug: data?.entity?.slug || "",
      from_page_section: "",
      from_page_section_entity: "",
      from_page_section_slug: "",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update drawer state when URL changes
  useEffect(() => {
    setIsGiftDrawerOpen(!!router.query.send_gift);
  }, [router.query.send_gift]);

  return (
    <div className="w-full max-w-[1256px] px-2 mx-auto">
      {!!user.data?.digital_gifts?.length &&
        <div className="px-3 my-4 sm:mb-10 xs:mb-5 mb-5">
          <div className="mt-32 "></div>
          <GiftRecipientBanner digital_gifts={user.data?.digital_gifts} />
        </div>
      }
      {syncData?.allow_digital_gift &&
        <div dir={router.locale?.startsWith('ar') ? 'rtl' : 'ltr'} className="px-3 my-4 sm:mb-10 xs:mb-5 mb-5">
          <GiftSenderBanner onClick={() => setIsGiftDrawerOpen(true)} />
        </div>
      }
      <GiftSenderDrawer
        isOpen={isGiftDrawerOpen}
        onClose={() => setIsGiftDrawerOpen(false)}
      />
      {syncData?.allow_donation &&
        <div className="px-3 my-4 sm:mb-10 xs:mb-5 mb-5">
          <HomeDonationBanner donations_count={syncData?.donations_count} />
        </div>
      }
      {user.isLoggedIn && syncData?.app_settings.active_order_tracker ? <ActiveOrder /> : null}
      {data?.items.map((item) => (
        <div
          key={`home-${item.e?.v ?? item.items[0]?.id}`}
          className="sm:mb-10 xs:mb-5 mb-5"
        >
          {item.type === "slider" && (
            <div>
              <Carousel data={item} sourceEntity={data.entity} />
            </div>
          )}
          {item.type === "video_sku" && (
            <div>
              <VideoList data={item} sourceEntity={data.entity} />
            </div>
          )}
          {item.type == "category" && (
            <div >
              <Categories data={item} sectionEntity={data.entity} />
            </div>
          )}
          {item.type === "product" && (
            <div>
              <CollectionList data={item} sourceEntity={data.entity} />
            </div>
          )}
          {item.type === "widget" && (
            <div>
              <Widget data={item} sourceEntity={data.entity} />
            </div>
          )}
          {item.type === "brand" && (
            <div>
              <Brand data={item} sectionEntity={data.entity} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Home;
