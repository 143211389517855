import React, { Fragment, useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useTranslations } from 'next-intl';
import Button from '@elements/button';
import LeftArrow from '@svg/LeftArrow';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import TextInput from '@elements/fields/textInput';
import Select from '@elements/fields/Select';
import TextAreaInput from '@elements/fields/textAreaInput';
import Info from '@svg/Info';
import { digitalGiftApi } from 'gate';
import { IDigitalGiftSendingOption, IDigitalGiftSkusInput, IProductDetailModel } from '@types';
import PhoneInput from '@elements/fields/phoneInput';
import { CartItemModel } from '@types';
import { addToCart } from '@utils/addToCart';
import { useAppContext } from '@context/app';
import { useRouter } from 'next/router';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const GiftSenderDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const { setUserCart, country } = useAppContext();
  const t = useTranslations();
  const router = useRouter();
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedSendingOption, setSelectedSendingOption] = useState<IDigitalGiftSendingOption | null>(null);
  const [selectedSku, setSelectedSku] = useState<number | null>(null);
  const [giftSkusData, setGiftSkusData] = useState<IDigitalGiftSkusInput | null>(null);
  const [isSkusLoading, setIsSkusLoading] = useState(false);

  // Get send_gift parameter from URL
  const sendGiftParam = router.query.send_gift as string | undefined;

  // Fetch digital gift SKUs when drawer opens
  useEffect(() => {
    if (isOpen && !giftSkusData) {
      setIsSkusLoading(true);
      digitalGiftApi.getSkus().fetch()
        .then(response => {
          setGiftSkusData(response.data);
          // Check if send_gift parameter matches any SKU ID
          if (sendGiftParam) {
            const skuId = parseInt(sendGiftParam);
            const matchingSku = response.data.skus.find(sku => sku.id === skuId);
            if (matchingSku) {
              setSelectedSku(skuId);
            }
          }
        })
        .catch(error => {
          console.error('Error fetching digital gift SKUs:', error);
        })
        .finally(() => {
          setIsSkusLoading(false);
        });
    }
  }, [isOpen, giftSkusData, sendGiftParam]);

  // Reset selected options when drawer closes
  useEffect(() => {
    if (!isOpen) {
      setSelectedSendingOption(null);
      setSelectedSku(null);
    }
  }, [isOpen]);

  // Update selected sending option when transfer method changes
  const handleTransferMethodChange = (methodId: number) => {
    const option = giftSkusData?.sending_options.find(opt => opt.id === methodId) || null;
    setSelectedSendingOption(option);
  };

  const steps = {
    1: {
      title: t("Select Recipient"),
      fields: ['recipientName', 'transferMethod', 'transferAmount', 'message', 'recipientPhone', 'recipientEmail'],
      validationSchema: Yup.object().shape({
        recipientName: Yup.string().required(t('Name is required')),
        transferMethod: Yup.string().required(t('Please Select a transfer method')),
        transferAmount: Yup.string().required(t('Please Select a transfer amount')),
        recipientPhone: selectedSendingOption?.action_type === 'sms' ? Yup.string().test('same-country-check', t('The recipients country does not match your registered country Digital gift cards are only available for recipients in your country'), async (value) => {
          try {
            const { parsePhoneNumber: parse } = await import('libphonenumber-js');
            const phoneNumber = parse(value || '');
            return phoneNumber.number.startsWith(country?.dialcode || '');
          } catch (error) {
            return false;
          }
        }).test('validate-phone', t('Please enter a valid mobile number'), async (value) => {
          try {
            const { parsePhoneNumber: parse } = await import('libphonenumber-js');
            const phoneNumber = parse(value || '');
            return phoneNumber.isValid();
          } catch (error) {
            return false;
          }
        }).required(t('is required'))
          : Yup.mixed(),
        recipientEmail: selectedSendingOption?.action_type === 'email' ? Yup.string().email('Please enter a valid email address').required('is required') : Yup.mixed(),
      }),
    },
    2: {
      title: t("Select Recipient"),
      fields: ['sendTime'],
      validationSchema: Yup.object().shape({})
    },
    3: {
      title: t("Select Recipient"),
      fields: ['giftWrap', 'giftCard'],
      validationSchema: Yup.object().shape({})
    }
  };

  const initialValues = {
    recipientName: '',
    transferMethod: '',
    transferAmount: sendGiftParam || '',
    recipientPhone: '',
    recipientEmail: '',
    message: '',
    occasion: '',
    giftWrap: false,
    giftCard: false,
  };
  const handleSubmit = async (values: any) => {
    try {
      // Prepare data for API
      const submitData = {
        recipient_name: values.recipientName,
        notification_method_id: parseInt(values.transferMethod),
        recipient_phone_number: selectedSendingOption?.action_type === 'sms' ? values.recipientPhone : undefined,
        recipient_email: selectedSendingOption?.action_type === 'email' ? values.recipientEmail : undefined,
        sku_id: parseInt(values.transferAmount),
        personalized_message: values.message || undefined,
        type: "Eidiyah" // Default type for Eidyah
      };
      // Submit to API
      const response = await digitalGiftApi.addDigitalGift(submitData).fetch();
      // Get the selected SKU details
      const selectedSkuDetails = giftSkusData?.skus.find(sku => sku.id === parseInt(values.transferAmount));
      if (selectedSkuDetails && response.data) {
        const digitalGiftCartItem: IProductDetailModel = {
          ...(selectedSkuDetails.product as IProductDetailModel),
          default_sku_id: selectedSku || -1,
          skus: giftSkusData?.skus || [],
        }
        // Get current cart
        const currentCart: CartItemModel[] = JSON.parse(
          window.localStorage.getItem("DABDOOB_CART") ?? "[]"
        );
        setUserCart(addToCart(currentCart, digitalGiftCartItem, () => window.location.replace('/cart'), undefined, response.data))
        // Close drawer on success
        onClose();
      } else {
        console.error('Missing SKU details or digital gift ID');
      }
    } catch (error) {
      console.error('Error submitting digital gift:', error);
    }
  };

  // Transform sending options to select options format
  const sendingOptionsForSelect = giftSkusData?.sending_options.map(option => ({
    id: option.id.toString(),
    name: option.action_type === 'sms' ? t('SMS') : t('Email')
  })) || [];

  return (
    <>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 backdrop-brightness-50 z-[58]"
          onClick={onClose}
        />
      </Transition>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-x-full"
        enterTo="opacity-100 translate-x-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 translate-x-full"
      >
        <div className="fixed right-0 top-0 h-full w-screen md:max-w-[643px] bg-[#F9F9F9] z-[59] overflow-y-auto">
          <div className="p-6 h-full flex flex-col">
            <div className="flex gap-2 items-center mb-8 rtl:justify-between rtl:flex-row-reverse">
              <div onClick={() => {
                if (currentStep === 1) return onClose()
                setCurrentStep((prev) => prev - 1)
              }} className='cursor-pointer'>
                <LeftArrow />
              </div>
              <h1 className="text-lg font-bold text-gray-800 uppercase">
                {steps[currentStep as keyof typeof steps].title}
              </h1>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={steps[currentStep as keyof typeof steps].validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleChange, handleBlur, setFieldValue, validateForm, setTouched }) => (
                <Form className="flex-1 flex flex-col [&_input]:bg-white [&_textarea]:bg-white">
                  <div className="flex-1">
                    {currentStep === 1 && (
                      <div className='flex flex-col gap-4'>
                        <p>{t("Enter the recipients details to send a Dabdoob Gift Card")}</p>
                        <TextInput
                          name="recipientName"
                          placeholder={t('Recipient Full Name')}
                          value={values.recipientName}
                          error={touched.recipientName ? errors.recipientName : undefined}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <Select
                          className={'bg-white [&_button]:border-none [&_li]:capitalize'}
                          label={''}
                          name='transferMethod'
                          placeholder={t('How would you like to send the gift card')}
                          options={sendingOptionsForSelect}
                          onChange={(value: any) => {
                            setFieldValue('transferMethod', value);
                            handleTransferMethodChange(parseInt(value));
                          }}
                        />

                        {selectedSendingOption?.action_type === 'sms' && (
                          <PhoneInput
                            name="recipientPhone"
                            placeholder={t('Recipient Phone Number')}
                            value={values.recipientPhone}
                            error={touched.recipientPhone ? errors.recipientPhone : undefined}
                            onChange={(value: any) => setFieldValue('recipientPhone', value)}
                            onBlur={handleBlur}
                          />
                        )}

                        {selectedSendingOption?.action_type === 'email' && (
                          <TextInput
                            name="recipientEmail"
                            placeholder={t('Recipient Email')}
                            value={values.recipientEmail}
                            error={touched.recipientEmail ? errors.recipientEmail : undefined}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                        {isSkusLoading ? (
                          <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
                        ) : (
                          <div className="flex flex-col gap-1">
                            <label htmlFor="" className='block'>{t('Select amount to send')}</label>
                            <div className="w-full flex flex-wrap gap-2">
                              {giftSkusData?.skus.map((sku) => (
                                <label key={sku.id} className="cursor-pointer">
                                  <input
                                    type="radio"
                                    name="transferAmount"
                                    value={sku.id}
                                    checked={values.transferAmount === sku.id.toString()}
                                    onChange={() => {
                                      setFieldValue('transferAmount', sku.id.toString());
                                      setSelectedSku(sku.id);
                                    }}
                                    className="hidden"
                                  />
                                  <span
                                    className={`rounded-[20px] py-2 px-5 mb-2 block font-normal text-sm !w-max cursor-pointer ${values.transferAmount === sku.id.toString()
                                      ? "border-[#008AF6] text-[#008AF6] border bg-[#008AF6] bg-opacity-10"
                                      : "border-[#DCDCDC] text-[#8D8D8D] border bg-[#F3F3F3]"
                                      }`}
                                  >
                                    {sku.price.price}
                                  </span>
                                </label>
                              ))}
                            </div>
                            <span className={"text-red-500 text-xs font-normal"}>
                              {touched.transferAmount && errors.transferAmount}
                            </span>
                          </div>
                        )}
                        <TextAreaInput
                          name="message"
                          placeholder={t('Add Personalized Message optional')}
                          error={touched.message ? errors.message : undefined}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                        />

                        <div className="flex items-center p-4 border border-orange-300 bg-orange-50 rounded-lg text-orange-600">
                          <Info className="w-8 h-8 mt-0.5" />
                          <p className="ml-2 text-sm">
                            {t('Gift cards are nonrefundable Only digital payment methods are accepted')}
                          </p>
                        </div>
                      </div>
                    )}

                    {currentStep === 2 && (
                      <div className='flex flex-col gap-4'>
                        <div className='h-64 bg-blue-300 w-full rounded'></div>
                        <p>{t('The recipient will receive a link to claim their gift')}</p>
                      </div>
                    )}

                  </div>

                  <div className="flex justify-between mt-6">
                    <Button
                      onClick={async () => {
                        const errors = await validateForm();
                        const stepFields = steps[currentStep as keyof typeof steps].fields;
                        setTouched(
                          stepFields.reduce((acc, field) => {
                            acc[field] = true;
                            return acc;
                          }, {} as Record<string, boolean>)
                        );
                        const hasErrors = stepFields.some((field) => errors[field as keyof typeof errors]);

                        if (!hasErrors) {
                          if (currentStep < 1) {
                            setCurrentStep(currentStep + 1);
                          } else {
                            handleSubmit(values);
                          }
                        }
                      }}
                      className="px-6 rounded-full w-full"
                    >
                      {t('Next')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default GiftSenderDrawer; 