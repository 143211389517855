import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React from 'react';

interface Props {
  onClick: () => void;
}

const GiftSenderBanner: React.FC<Props> = ({ onClick }) => {
  const t = useTranslations();

  return (
    <div
      className="relative w-full overflow-hidden rounded-xl cursor-pointer"
      onClick={onClick}
    >
      {/* Background pattern */}
      <div className="absolute inset-0 bg-[#ED2E7B]">
        <div className="absolute inset-0 bg-[url(/svgs/gift-sender-banner-background.svg)]"  />
      </div>

      {/* Content */}
      <div className="flex justify-between items-center">
        <div className="relative px-4 lg:px-8 lg:max-w-screen-lg py-8 text-white">
          <h2 className="md:text-3xl sm:text-2xl text-xl font-extrabold mb-2 uppercase">
            {t('MAKE EVERY OCCASION SPECIAL WITH DABDOOB GIFT CARDS')}
          </h2>
          <p className="">
            {t('Give friends and family the freedom to choose from a world of fun Its the perfect gift every time')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GiftSenderBanner; 