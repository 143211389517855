import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React, { useState } from 'react';
import GiftRecipientDrawer from './GiftRecipientDrawer';
import { IUserProfileInput } from '@types';
interface Props {
  digital_gifts: IUserProfileInput['digital_gifts']
}
const GiftRecipientBanner: React.FC<Props> = ({digital_gifts}) => {
  const t = useTranslations();
  const [showDrawer, setShowDrawer] = useState(false)
  return (
    <>
      <div
        className="w-full overflow-hidden  cursor-pointer fixed mt-[70px] sm:mt-[131px] inset-0 z-10 h-28"
        onClick={() => setShowDrawer(true)}
      >
        {/* Background pattern */}
        <div className="absolute inset-0 bg-[#008EC6]">
          <div className="absolute inset-0  bg-[url(/svgs/gift-recipient-banner-background.svg)]" />
        </div>

        {/* Content */}
        <div className="flex justify-between items-center h-full">
          <h2 className="relative px-4 lg:px-8 m-0 lg:max-w-screen-lg py-8 text-white md:text-3xl sm:text-2xl my-auto text-xl font-extrabold uppercase">
            {t('YOU GOT A GIFT CARD TAP TO CLAIM')}
          </h2>
          <div className="md:h-[75px] md:w-[75px] h-[45px] w-[45px] scale-90 mx-8">
            <Image
              src="/imgs/dabdoob-gift-card-recipient-banner.png"
              alt="gift-box"
              width={179}
              height={145}
              priority
            />
          </div>
        </div>
      </div>
      <GiftRecipientDrawer
        isOpen={showDrawer}
        digital_gifts={digital_gifts}
        onClose={() => setShowDrawer(false)}
      />
    </>
  );
};

export default GiftRecipientBanner; 