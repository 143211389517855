import Modal from "@modules/layout/modal";
import { positiveNumberPattern } from "@utils/constants";
import cn from "classnames";
import { commonApi } from "gate";
import { useTranslations } from "next-intl";
import Image from "next/image";
import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { useRouter } from "next/router";
import PathIcon from "@svg/PathIcon";
import usePhoneInput from "@hooks/usePhoneInput";
import useRequestImmutable from "@hooks/useRequestImmutable";

interface PhoneFieldProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "ref"
  > {
  label?: string;
  isRequired?: boolean;
  error?: string;
  placeholder?: string;
  className?: string;
  id?: string;
  inputClassName?: string;
  noLabelPlaceholder?: boolean;
  icon?: React.ReactNode;
  errorClassName?: string;
  positionError?: "top" | "bottom";
  isPassword?: boolean;
}


const PhoneField = React.forwardRef<HTMLInputElement, PhoneFieldProps>(
  (
    {
      error = "",
      isRequired = false,
      label = "",
      placeholder,
      className,
      id,
      type,
      noLabelPlaceholder = false,
      inputClassName,
      icon = null,
      errorClassName,
      positionError = "bottom",
      isPassword,
      ...props
    },
    ref
  ) => {
    const t = useTranslations();
    const { locale } = useRouter();
    const { data: countryList } = useRequestImmutable(commonApi.getCountryList());
    const { openCountries, setOpenCountries, handelSelectedCountry, value, handelChangeValue, selectedCountry } = usePhoneInput(countryList?.data.items ?? [], (e) => props.onChange?.(e), typeof props.value === 'string' ? props.value : undefined)

    const _id = "input";
    return (
      <>
        {openCountries && (
          <Modal
            open={openCountries}
            onClose={() => setOpenCountries(false)}
            cancelable
          >
            <div className=" md:w-[350px] sm:w-screen xs:w-screen w-screen max-w-[400px] min-h-[300px]  bg-white rounded-lg p-4">
              <div className=" w-full mb-5 ">
                <p className="flex-1 font-normal text-xl text-[#333] text-center">
                  {t("client_myaddresses_pickcountrycode")}
                </p>
              </div>
              <div className="w-full">
                {countryList?.data.items.map((c) => (
                  <div
                    className=" flex flex-wrap h-14 items-center border-b cursor-pointer"
                    onClick={() => handelSelectedCountry(c)}
                    key={`country-${c.id}`}
                  >
                    <div className="w-6 h-6 rounded-full relative overflow-hidden">
                      <Image src={c.image.url} layout="fill" alt="" />
                    </div>
                    <p className=" flex-1 font-normal text-sm text-[#333] flex justify-start px-3">
                      {c.name}
                    </p>
                    <p className="font-normal text-sm text-[#333]">
                      {c.dialcode}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Modal>
        )}

        <div
          dir='ltr'

          className="md:w-full sm:w-full xs:w-full w-full p-1">
          <div className="form__group w-full h-14 bg-[#F5F5F5] rounded-xl relative">
            <input
              id={_id}
              ref={ref}
              value={value}
              onChange={(e) => {
                const v = e.target.value;
                if (
                  new RegExp(positiveNumberPattern, "i").test(v as string) ||
                  !v
                ) {
                  handelChangeValue(v);
                }
              }}
              type="text"
              className={cn(
                "form__control border-2 border-[transparent] outline-0 bg-transparent h-full w-full rounded-xl pt-[10px] pb-2 px-4 pl-24 text-sm font-normal",
                error.length ? "err-input" : ""
              )}
              autoComplete="nope"
              onBlur={props.onBlur}
            />
            <label className=" absolute top-[18px] left-0 pl-2 text-[#333] text-sm font-normal flex items-center">
              <div className="flex items-center cursor-pointer" onClick={() => setOpenCountries(true)}>
                <div
                  className=" w-6 h-6 rounded-full overflow-hidden border mr-1 relative "

                >
                  <Image
                    src={selectedCountry?.image.url || ""}
                    alt=""
                    layout="fill"
                  />

                </div>{" "}
                <PathIcon
                  stroke="#000"
                  className={`mx-1 rotate-90`}
                />
              </div>

              <span className="px-1">{selectedCountry?.dialcode}</span>

            </label>
            {locale?.includes("ar") ? (
              <label
                className={cn(
                  "form__label px-4 text-[#999999] text-sm font-normal absolute ",
                  error ? "err-label" : "",
                  value ? "top-label" : "",
                  "right-0"
                )}
              >
                {label}
              </label>
            ) : (
              <label
                className={cn(
                  "form__label-top px-9 text-[#999999] text-sm font-normal",
                  error ? "err-label" : "",
                  "top-label",
                  "left-0"
                )}
              >
                {label}
              </label>
            )}
          </div>
          {error && (
            <div className={`w-full mt-1 pl-2 flex  ${locale?.includes("ar") ? " justify-end" : "justify-start"} `}>
              <span
                className={cn(
                  "text-red-500 text-xs font-normal",
                  errorClassName
                )}
              >
                {error}
              </span>
            </div>
          )}
        </div>
      </>
    );
  }
);

PhoneField.displayName = "PhoneField";

export default PhoneField;
