import { CartItemModel, IProductDetailModel, ISkuModel } from "@types";

function findCartItemIndex(cart: CartItemModel[], skuId: number): number {
  return cart.findIndex((item) => item.id === skuId);
}

function updateCartItemQuantity(
  cart: CartItemModel[],
  skuId: number,
  qty: number,
  digital_gift_id?: number
): CartItemModel[] {
  return cart.map((item) => {
    if (item.id === skuId) {
      // If this is a digital gift update, add the new digital_gift_id
      if (digital_gift_id) {
        const existingIds = item.digital_gift_ids || [];
        return {
          ...item,
          qty: item.qty + qty,
          digital_gift_ids: [...existingIds, digital_gift_id]
        };
      }
      return { ...item, qty: item.qty + qty };
    }
    return item;
  });
}

export function addToCart(
  cart: CartItemModel[],
  item: IProductDetailModel,
  callback: () => void,
  qty = 1,
  digital_gift_id?: number
): CartItemModel[] {
  const defaultSku: ISkuModel | undefined = item.skus.find((sku) => sku.id === item.default_sku_id);
  const currentCart: CartItemModel[] = JSON.parse(
    window.localStorage.getItem("DABDOOB_CART") ?? "[]"
  );
  if (!defaultSku) return [];
  const cartItemIndex: number = findCartItemIndex(currentCart, defaultSku.id);

  if (cartItemIndex !== -1) {
    const updatedCart = updateCartItemQuantity(currentCart, defaultSku.id, qty, digital_gift_id);
    callback();
    return [...updatedCart];
  } else {
    callback();

    const newCartItem: CartItemModel = {
      differentOptions: false,
      image: defaultSku.medias[0].url,
      qty,
      productName: item.name,
      productSlug: item.slug,
      skuName: defaultSku.name,
      price: defaultSku.price.price,
      raw_price: defaultSku.price.raw_price,
      gifts: [],
      id: defaultSku.id,
      buy_limit: defaultSku.buy_limit,
      productId: item.id,
      discount: defaultSku.price.old_price
        ? defaultSku.price.old_price - defaultSku.price.price
        : 0,
      is_customizable: item.is_customizable,
      skuSlug: defaultSku.slug,
      oldPrice: defaultSku.price.old_price ?? undefined,
      brand_id: item.brand?.id,
      brand_slug: item.brand?.slug,
      category_id: item.category?.id,
      category_slug: item.category?.slug,
      subcategory_id: item.subcategory?.id,
      subcategory_slug: item.subcategory?.slug,
      digital_gift_ids: digital_gift_id ? [digital_gift_id] : undefined,
    };

    return [...currentCart, newCartItem];
  }
}
