import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const UNHCRLogo: React.FC<Props> = (props) => {
    return (
        <svg width="302" height="123" viewBox="0 0 302 123" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6887_7489)">
                <path d="M66.6149 71.918C67.2905 71.918 67.206 71.5311 67.206 70.8003V67.2538C67.206 66.523 67.1427 66.2865 67.5544 66.2865C68.9899 66.2865 69.3805 66.1468 69.3805 64.8572V51.6705C69.3805 48.081 66.8894 48.6721 66.1927 47.361C65.19 45.5877 68.0505 45.5877 67.016 41.7403C66.7522 40.8698 66.0872 40.3324 64.7889 40.3324C63.4905 40.3324 62.8256 40.8698 62.5617 41.7403C61.5272 45.5877 64.3878 45.5877 63.385 47.361C62.6778 48.6721 60.1973 48.081 60.1973 51.6705V64.8572C60.1973 66.1576 60.5773 66.2865 62.0233 66.2865C62.4245 66.2865 62.3611 66.523 62.3611 67.2538V70.8003C62.3611 71.5311 62.2767 71.918 62.9522 71.918H66.6255" fill="#0072BC" />
                <path d="M55.2785 43.3201C54.0013 44.6957 51.9957 47.9198 53.3046 50.5958C55.9751 51.6383 55.7746 41.9767 59.0995 42.1486C60.7461 43.6962 58.6034 48.253 57.949 50.2627C57.1257 52.7345 56.4712 57.9253 54.909 60.4294C53.6002 62.5251 54.5818 69.0915 54.1701 71.3484C53.0618 72.4768 50.0641 71.6815 48.7869 71.4666C48.7869 68.8766 48.4597 66.8239 48.048 62.6863C48.0058 61.848 47.1403 49.0483 47.8475 47.5007C49.283 44.2766 57.1362 38.1293 58.2023 36.9578C59.1417 35.9154 62.4667 32.4441 63.7439 32.4441C64.6411 33.0781 64.1133 34.5397 63.9128 35.1201C62.6356 38.3442 57.1362 41.5146 55.2785 43.3201Z" fill="#0072BC" />
                <path d="M74.4258 43.3201C75.6502 44.6957 77.6769 47.9198 76.3997 50.5958C73.6869 51.6383 73.898 41.9767 70.5625 42.1486C68.9687 43.6962 71.1009 48.253 71.7553 50.2627C72.5786 52.7345 73.233 57.9253 74.7952 60.4294C76.0724 62.5251 75.1225 69.0915 75.4919 71.3484C76.6424 72.4768 79.5979 71.6815 80.9174 71.4666C80.9174 68.8766 81.2024 66.8239 81.614 62.6863C81.6985 61.848 82.5534 49.0483 81.8568 47.5007C80.4213 44.2766 72.5786 38.1293 71.502 36.9578C70.5203 35.9154 67.227 32.4441 65.9498 32.4441C65.0104 33.0781 65.5804 34.5397 65.7915 35.1201C67.0687 38.3442 72.5681 41.5146 74.4258 43.3201Z" fill="#0072BC" />
                <path d="M43.8682 38.9783C43.1715 39.6553 41.5987 40.1067 40.5327 41.5683C40.121 40.773 41.1871 38.8493 42.4643 37.7209C43.4037 36.9256 45.2615 35.6252 45.7892 35.4962C46.2009 36.4635 44.7231 37.9251 43.8576 38.9783" fill="#0072BC" />
                <path d="M41.6831 44.2228C40.4904 45.3513 39.2132 45.8134 37.5665 47.8661C36.8699 45.3943 37.0388 43.2126 37.3554 41.7403C37.936 39.8165 38.8754 37.6779 40.4904 37.0116C40.1631 38.1078 39.6248 39.6124 39.4982 41.665C38.8015 44.47 38.3898 44.556 38.5482 44.9321C38.7065 44.9321 39.6143 43.4598 40.7331 42.3314C42.0526 40.988 43.6992 40.6978 44.7653 39.9025C44.607 40.4506 43.657 42.1594 41.6831 44.2228Z" fill="#0072BC" />
                <path d="M35.8778 55.8297C34.2733 52.8957 34.1044 50.757 33.9778 48.2852C34.0622 46.5657 34.8855 42.5893 36.1205 41.9982C35.7511 45.5985 36.7011 45.9316 36.3211 48.9086C36.3211 49.0805 35.9094 52.1757 35.9094 52.3476C36.4055 52.2616 35.9516 49.4996 41.5882 45.5125C40.3638 47.6941 40.9338 48.3605 38.7066 51.2622C36.1205 53.8629 35.7511 56.41 35.8778 55.8297Z" fill="#0072BC" />
                <path d="M38.147 58.6024C36.9543 60.3649 37.1548 61.2354 36.8698 62.0737C34.0304 60.3972 30.8216 53.734 32.0144 50.4239C32.7532 52.9387 33.7877 53.4868 34.5265 55.5825C35.8037 58.7314 35.6032 59.6878 36.416 60.3972C36.2471 58.7636 36.7854 56.8291 37.3237 55.4105C38.1048 54.1961 38.717 52.6055 39.2976 52.0574C39.9098 54.3681 39.4559 56.2488 38.147 58.6024Z" fill="#0072BC" />
                <path d="M40.7016 64.2983C40.7016 66.8561 40.2477 67.9846 40.0366 69.8653C35.1389 68.2318 32.5845 62.5358 32.5845 59.7201C34.0728 63.1591 36.2472 63.1162 39.0971 67.9738C39.3399 66.1253 38.601 67.5117 38.5166 64.0297C38.601 60.3864 39.3399 60.2574 39.456 57.9898C40.3638 58.9893 40.691 63.0194 40.691 64.2768" fill="#0072BC" />
                <path d="M44.7652 71.5526C45.1346 73.4333 45.3035 75.6579 45.5885 77.3022C42.7913 76.2598 42.0841 77.0443 38.7592 74.9164C37.2286 74.0781 34.5159 70.5638 34.6848 68.0383C37.9781 71.7675 38.548 71.3484 40.1525 72.7347C41.6302 73.9492 42.823 75.7547 43.8996 75.5075C43.9735 74.4973 41.4719 72.3156 41.7991 66.0393C42.4535 66.7486 44.2691 69.5966 44.7652 71.5741" fill="#0072BC" />
                <path d="M50.3278 76.249C51.1934 77.5064 50.9928 78.0545 53.2095 80.6446C52.6711 80.8918 49.5045 81.73 47.3934 81.8268C46.0001 81.8268 41.0602 80.4834 39.4136 77.0443C42.253 78.5919 42.2952 78.2158 46.0846 79.054C48.8079 79.6774 50.7395 80.5586 51.4784 80.4834C51.3095 80.1072 49.2195 79.226 47.7734 77.3775C46.3273 75.4108 46.6546 73.1861 45.3774 71.5526C46.2429 71.3054 49.6206 75.3248 50.3173 76.249" fill="#0072BC" />
                <path d="M85.604 38.9783C86.2585 39.6553 87.8734 40.1067 88.8973 41.5683C89.3512 40.773 88.2429 38.8493 86.9657 37.7209C86.0685 36.9256 84.2107 35.6252 83.6302 35.4962C83.2185 36.4635 84.6963 37.9251 85.604 38.9783Z" fill="#0072BC" />
                <path d="M87.7889 44.2228C88.9395 45.3513 90.2167 45.8134 91.8633 47.8661C92.5705 45.3943 92.4016 43.2126 92.0744 41.7403C91.5361 39.8165 90.5439 37.6779 88.9395 37.0116C89.3089 38.1078 89.805 39.6124 89.9317 41.665C90.6283 44.47 91.04 44.556 90.8817 44.9321C90.7233 44.9321 89.8156 43.4598 88.6967 42.3314C87.4301 40.988 85.7729 40.6978 84.6646 39.9025C84.8334 40.4506 85.8151 42.1594 87.7889 44.2228Z" fill="#0072BC" />
                <path d="M93.5521 55.8297C95.1988 52.8957 95.3149 50.757 95.4416 48.2852C95.3571 46.5657 94.5338 42.5893 93.341 41.9982C93.6683 45.5985 92.7183 45.9316 93.1299 48.9086C93.1299 49.0805 93.4994 52.1757 93.4994 52.3476C93.0455 52.2616 93.4571 49.4996 87.8628 45.5125C89.0556 47.6941 88.475 48.3605 90.7022 51.2622C93.3305 53.8629 93.6683 56.41 93.5416 55.8297" fill="#0072BC" />
                <path d="M91.325 58.6024C92.4861 60.3649 92.275 61.2354 92.56 62.0737C95.3994 60.3972 98.6505 53.734 97.4155 50.4239C96.7189 52.9387 95.6422 53.4868 94.9455 55.5825C93.6261 58.7314 93.8267 59.6878 93.0561 60.3972C93.2145 58.7636 92.6445 56.8291 92.1062 55.4105C91.3673 54.1961 90.7128 52.6055 90.1323 52.0574C89.5623 54.3681 89.9634 56.2488 91.325 58.6024Z" fill="#0072BC" />
                <path d="M88.7391 64.2983C88.7391 66.8561 89.2246 67.9846 89.4357 69.8653C94.2912 68.2318 96.8878 62.5358 96.8878 59.7201C95.3573 63.1591 93.2251 63.1162 90.3435 67.9738C90.1007 66.1253 90.829 67.5117 90.9135 64.0297C90.829 60.3864 90.0902 60.2574 89.9635 57.9898C89.0663 58.9893 88.7285 63.0194 88.7285 64.2768" fill="#0072BC" />
                <path d="M84.6646 71.5526C84.2952 73.4333 84.1685 75.6579 83.8835 77.3022C86.6385 76.2598 87.3457 77.0443 90.6707 74.9164C92.1906 74.0781 94.9561 70.5638 94.7873 68.0383C91.494 71.7675 90.8818 71.3484 89.3196 72.7347C87.8313 73.9492 86.5963 75.7547 85.5302 75.5075C85.4457 74.4973 87.9579 72.3156 87.6729 66.0393C86.9763 66.7486 85.1607 69.5966 84.6646 71.5741" fill="#0072BC" />
                <path d="M79.1018 76.249C78.2785 77.5064 78.4791 78.0545 76.2202 80.6446C76.7902 80.8918 79.9252 81.73 82.0679 81.8268C83.4295 81.8268 88.3695 80.4834 90.0055 77.0443C87.1767 78.5919 87.1239 78.2158 83.3451 79.054C80.6218 79.6774 78.7324 80.5586 77.9513 80.4834C78.1096 80.1072 80.2524 79.226 81.6562 77.3775C83.0918 75.4108 82.7646 73.1861 84.084 71.5526C83.1868 71.3054 79.809 75.3248 79.1018 76.249Z" fill="#0072BC" />
                <path d="M57.6956 88.06L56.8723 87.6409C56.8723 87.6409 62.1078 81.1497 71.0799 81.6441C80.0519 82.1492 79.4397 84.2878 84.253 83.9439C84.7913 83.9009 83.8413 84.621 83.8413 84.621C83.8413 84.621 79.1442 86.4587 73.3387 84.449C67.5755 82.4393 63.8283 81.2249 57.6956 88.0493" fill="#0072BC" />
                <path d="M71.8926 88.06L72.7265 87.6409C72.7265 87.6409 67.5332 81.1497 58.5612 81.6441C49.5469 82.1492 50.1591 84.2878 45.3458 83.9439C44.8075 83.9009 45.7575 84.621 45.7575 84.621C45.7575 84.621 50.4441 86.4587 56.2495 84.449C62.055 82.4393 65.8022 81.2249 71.8926 88.0493" fill="#0072BC" />
                <path d="M135.521 59.7953C135.521 68.5541 130.876 72.7562 121.408 72.7562C111.94 72.7562 107.243 68.5541 107.243 59.7953V40.4291H116.795V57.6351C116.795 60.8593 116.795 64.8894 121.44 64.8894C126.084 64.8894 125.957 60.8593 125.957 57.6351V40.4291H135.51V59.7953" fill="#0072BC" />
                <path d="M141.252 40.4291H151.026L160.062 57.3235H160.146V40.4291H169.181V72.0039H159.882L150.372 54.7549H150.287V72.0039H141.252V40.4291Z" fill="#0072BC" />
                <path d="M175.04 40.4291H184.592V51.4018H193.543V40.4291H203.096V72.0039H193.543V59.4944H184.592V72.0039H175.04V40.4291Z" fill="#0072BC" />
                <path d="M227.553 52.1971C227.384 51.0472 226.508 47.5544 222.56 47.5544C218.085 47.5544 216.66 51.9714 216.66 56.2165C216.66 60.4616 218.095 64.8894 222.56 64.8894C225.779 64.8894 227.035 62.5895 227.605 59.6233H236.989C236.989 65.9856 231.912 72.7562 222.834 72.7562C212.807 72.7562 207.107 65.545 207.107 56.2165C207.107 46.2648 213.271 39.6768 222.834 39.6768C231.342 39.7198 236.039 44.2766 236.863 52.1971H227.574" fill="#0072BC" />
                <path d="M250.532 47.9413H255.007C258.659 47.9413 259.747 49.0913 259.747 51.0795C259.747 53.9059 257.308 54.2176 255.535 54.2176H250.542V47.9413H250.532ZM240.969 72.004H250.521V60.999H255.609C259.388 60.999 259.641 64.1371 259.905 67.1463C260.032 68.7799 260.211 70.4134 260.686 72.004H270.239C269.363 70.5424 269.331 66.7916 269.194 65.2441C268.845 61.3107 267.241 58.3875 264.634 57.3665C267.8 56.1736 269.278 52.7238 269.278 49.4567C269.278 43.4813 264.581 40.4399 259.155 40.4399H240.958V72.0147" fill="#0072BC" />
                <path d="M110.346 79.2582H107.232V77.3022H115.75V79.2582H112.636V87.8881H110.346V79.2582Z" fill="#0072BC" />
                <path d="M116.458 77.3022H118.526V81.2894H118.569C119.096 80.4296 119.994 80.0212 120.764 80.0212C122.96 80.0212 123.519 81.2787 123.519 83.1809V87.8988H121.45V83.5678C121.45 82.3104 121.091 81.687 120.131 81.687C119.012 81.687 118.526 82.3211 118.526 83.8794V87.8988H116.458V77.313" fill="#0072BC" />
                <path d="M126.474 84.5673C126.527 85.8999 127.171 86.5125 128.322 86.5125C129.155 86.5125 129.82 85.9966 129.947 85.5238H131.763C131.182 87.3293 129.947 88.1031 128.237 88.1031C125.873 88.1031 124.395 86.4373 124.395 84.0729C124.395 81.7086 125.957 80.032 128.237 80.032C130.802 80.032 132.037 82.2244 131.889 84.578H126.464L126.474 84.5673ZM129.831 83.2346C129.641 82.1707 129.187 81.6011 128.184 81.6011C126.876 81.6011 126.496 82.6435 126.464 83.2346H129.82" fill="#0072BC" />
                <path d="M145.326 83.8902C145.326 86.7489 143.669 88.1353 140.893 88.1353C138.117 88.1353 136.47 86.7596 136.47 83.8902V77.313H138.75V83.8902C138.75 85.0401 139.046 86.19 140.893 86.19C142.519 86.19 143.046 85.4592 143.046 83.8902V77.313H145.326V83.8902Z" fill="#0072BC" />
                <path d="M146.846 77.3022H149.116L153.454 84.406H153.486V77.3022H155.629V87.8881H153.338L149.021 80.8058H148.989V87.8881H146.846V77.3022Z" fill="#0072BC" />
                <path d="M162.89 79.1185H165.392C166.405 79.1185 166.965 79.5591 166.965 80.5801C166.965 81.6011 166.416 82.0954 165.392 82.0954H162.89V79.1185ZM160.6 87.8881H162.88V83.7505H165.16C166.31 83.7505 166.733 84.2448 166.891 85.3518C167.007 86.2008 166.975 87.2218 167.25 87.8881H169.53C169.118 87.297 169.139 86.0503 169.097 85.384C169.023 84.3201 168.706 83.2024 167.588 82.9122V82.88C168.738 82.4071 169.234 81.4721 169.234 80.2254C169.234 78.6241 168.052 77.3022 166.194 77.3022H160.589V87.8773" fill="#0072BC" />
                <path d="M172.338 83.2346C172.369 82.6435 172.739 81.6011 174.058 81.6011C175.061 81.6011 175.515 82.1599 175.705 83.2346H172.338ZM177.763 84.5672C177.911 82.2136 176.676 80.0212 174.111 80.0212C171.831 80.0212 170.269 81.773 170.269 84.0621C170.269 86.3513 171.736 88.0923 174.111 88.0923C175.81 88.0923 177.045 87.3185 177.636 85.513H175.821C175.684 85.9859 175.019 86.5017 174.195 86.5017C173.045 86.5017 172.401 85.8999 172.348 84.5565H177.774" fill="#0072BC" />
                <path d="M179.125 81.6333H177.89V80.2254H179.125V79.6344C179.125 78.2802 179.948 77.3022 181.637 77.3022C181.996 77.3022 182.376 77.3452 182.724 77.3667V78.9358C182.481 78.9036 182.228 78.8928 181.964 78.8928C181.415 78.8928 181.183 79.1292 181.183 79.7633V80.2254H182.608V81.6333H181.183V87.8881H179.114V81.6333" fill="#0072BC" />
                <path d="M190.314 87.8881H188.35V86.8242H188.308C187.78 87.6839 186.883 88.1031 186.007 88.1031C183.811 88.1031 183.252 86.8456 183.252 84.9434V80.2362H185.321V84.5565C185.321 85.8139 185.68 86.4373 186.64 86.4373C187.759 86.4373 188.245 85.8032 188.245 84.2449V80.2255H190.314V87.8881Z" fill="#0072BC" />
                <path d="M195.074 86.0826C193.807 86.0826 193.406 84.8789 193.406 83.7827C193.406 82.6865 193.913 81.6011 195.074 81.6011C196.415 81.6011 196.837 82.708 196.837 83.9547C196.837 85.0509 196.256 86.0718 195.074 86.0718M198.8 80.2254H196.837V81.2464H196.805C196.309 80.3544 195.56 80.0212 194.567 80.0212C192.456 80.0212 191.337 81.8267 191.337 83.8149C191.337 85.9214 192.298 87.6731 194.557 87.6731C195.454 87.6731 196.362 87.2863 196.816 86.491H196.847V87.5012C196.858 88.5974 196.341 89.3927 195.19 89.3927C194.462 89.3927 193.839 89.124 193.649 88.3395H191.601C191.696 90.0912 193.438 90.8005 194.916 90.8005C198.378 90.8005 198.811 88.6619 198.811 87.4045V80.2362" fill="#0072BC" />
                <path d="M201.903 83.2346C201.935 82.6435 202.304 81.6011 203.624 81.6011C204.627 81.6011 205.08 82.1599 205.27 83.2346H201.914H201.903ZM207.329 84.5672C207.477 82.2136 206.242 80.0212 203.677 80.0212C201.397 80.0212 199.834 81.773 199.834 84.0621C199.834 86.3513 201.302 88.0923 203.677 88.0923C205.376 88.0923 206.622 87.3185 207.202 85.513H205.387C205.249 85.9859 204.584 86.5017 203.761 86.5017C202.611 86.5017 201.967 85.8999 201.914 84.5565H207.339" fill="#0072BC" />
                <path d="M209.989 83.2346C210.02 82.6435 210.39 81.6011 211.709 81.6011C212.712 81.6011 213.166 82.1599 213.356 83.2346H209.999H209.989ZM215.414 84.5672C215.562 82.2136 214.327 80.0212 211.762 80.0212C209.482 80.0212 207.92 81.773 207.92 84.0621C207.92 86.3513 209.387 88.0923 211.762 88.0923C213.461 88.0923 214.696 87.3185 215.287 85.513H213.472C213.345 85.9859 212.67 86.5017 211.846 86.5017C210.696 86.5017 210.062 85.8999 209.999 84.5565H215.425" fill="#0072BC" />
                <path d="M224.872 79.9138H224.903L226.212 83.7935H223.521L224.872 79.9138ZM219.794 87.8881H222.106L222.919 85.5345H226.803L227.584 87.8881H229.959L226.075 77.313H223.732L219.784 87.8881H219.794Z" fill="#0072BC" />
                <path d="M233.844 86.0826C232.577 86.0826 232.165 84.8789 232.165 83.7827C232.165 82.6865 232.672 81.6011 233.844 81.6011C235.184 81.6011 235.607 82.708 235.607 83.9547C235.607 85.0509 235.026 86.0718 233.844 86.0718M237.57 80.2254H235.607V81.2464H235.575C235.079 80.3544 234.319 80.0212 233.337 80.0212C231.226 80.0212 230.107 81.8267 230.107 83.8149C230.107 85.9214 231.068 87.6731 233.327 87.6731C234.234 87.6731 235.132 87.2863 235.585 86.491H235.617V87.5012C235.628 88.5974 235.11 89.3927 233.96 89.3927C233.232 89.3927 232.609 89.124 232.419 88.3395H230.371C230.477 90.0912 232.208 90.8005 233.696 90.8005C237.158 90.8005 237.591 88.6619 237.591 87.4045V80.2362" fill="#0072BC" />
                <path d="M240.663 83.2346C240.694 82.6435 241.074 81.6011 242.383 81.6011C243.386 81.6011 243.84 82.1599 244.03 83.2346H240.663ZM246.099 84.5672C246.246 82.2136 245.011 80.0212 242.446 80.0212C240.156 80.0212 238.604 81.773 238.604 84.0621C238.604 86.3513 240.071 88.0923 242.446 88.0923C244.146 88.0923 245.381 87.3185 245.961 85.513H244.146C244.019 85.9859 243.344 86.5017 242.51 86.5017C241.359 86.5017 240.715 85.8999 240.663 84.5565H246.088" fill="#0072BC" />
                <path d="M246.996 80.2254H248.959V81.2894H249.001C249.529 80.4296 250.426 80.0212 251.302 80.0212C253.498 80.0212 254.057 81.2786 254.057 83.1809V87.8988H251.989V83.5678C251.989 82.3104 251.63 81.687 250.669 81.687C249.55 81.687 249.065 82.3211 249.065 83.8794V87.8988H246.996V80.2362" fill="#0072BC" />
                <path d="M260.433 82.9229C260.306 82.0632 259.768 81.6011 258.902 81.6011C257.583 81.6011 257.14 82.9659 257.14 84.0836C257.14 85.2013 257.562 86.5017 258.86 86.5017C259.821 86.5017 260.37 85.8784 260.507 84.9434H262.502C262.238 86.9746 260.855 88.0923 258.881 88.0923C256.612 88.0923 255.081 86.4587 255.081 84.1696C255.081 81.8805 256.475 80.0212 258.923 80.0212C260.697 80.0212 262.333 80.967 262.459 82.9229H260.433Z" fill="#0072BC" />
                <path d="M267.114 88.8446C266.66 90.0698 265.953 90.5749 264.528 90.5749C264.106 90.5749 263.684 90.5426 263.262 90.4996V88.7694C263.652 88.8016 264.064 88.8553 264.465 88.8446C265.172 88.7694 265.415 88.0171 265.172 87.4045L262.523 80.2147H264.739L266.439 85.4593H266.47L268.117 80.2147H270.26L267.114 88.8339" fill="#0072BC" />
            </g>
            <defs>
                <clipPath id="clip0_6887_7489">
                    <rect width="302" height="123" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default UNHCRLogo;
