import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const Indicator: React.FC<Props> = ({ fill = "#F6851F", ...props }) => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.8787 0.957373C10.5277 0.416553 11.4704 0.416553 12.1194 0.957373L14.2706 2.75005H17.4991C18.4656 2.75005 19.2491 3.53355 19.2491 4.50005V7.72851L21.0418 9.87976C21.5826 10.5287 21.5826 11.4714 21.0417 12.1204L19.2491 14.2716V17.5001C19.2491 18.4666 18.4656 19.2501 17.4991 19.2501H14.2706L12.1194 21.0428C11.4704 21.5836 10.5277 21.5836 9.8787 21.0428L7.72754 19.2501H4.49908C3.53258 19.2501 2.74908 18.4666 2.74908 17.5001L2.74907 14.2716L0.956389 12.1204C0.415579 11.4714 0.415579 10.5287 0.956389 9.87976L2.74907 7.72851V4.50005C2.74908 3.53355 3.53258 2.75005 4.49907 2.75005H7.72754L9.8787 0.957373ZM9.5 10.5001C9.5 9.94776 9.9477 9.50006 10.5 9.50006H11C11.8284 9.50006 12.5 10.1717 12.5 11.0001V15.5001C12.5 16.0524 12.0523 16.5001 11.5 16.5001C10.9477 16.5001 10.5 16.0524 10.5 15.5001V11.5001C9.9477 11.5001 9.5 11.0524 9.5 10.5001ZM11 6.50004C10.4477 6.50004 10 6.94776 10 7.50004C10 8.05233 10.4477 8.50004 11 8.50004H11.009C11.5613 8.50004 12.009 8.05233 12.009 7.50004C12.009 6.94776 11.5613 6.50004 11.009 6.50004H11Z"
                fill={fill}
            />
        </svg>
    );
};

export default Indicator;
