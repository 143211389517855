import React, { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { useTranslations } from 'next-intl';
import LeftArrow from '@svg/LeftArrow';
import { digitalGiftApi } from 'gate';
import { useAppContext } from '@context/app';
import RightChevron from '@svg/RightChevron'
import Image from 'next/image';
import { IUserProfileInput } from '@types';
import Button from '@elements/button';
import { useRouter } from 'next/router';
import DigitalGiftApi from '@api/digitalGiftApi';
interface Props {
  isOpen: boolean;
  onClose: () => void;
  digital_gifts: IUserProfileInput['digital_gifts']
}

const GiftRecipientDrawer: React.FC<Props> = ({ isOpen, onClose, digital_gifts }) => {
  const t = useTranslations();
  const { locale } = useRouter();
  const [isLoading, setIsLoading] = useState(false)
  const [selectedGift, setSelectedGift] = useState<NonNullable<IUserProfileInput['digital_gifts']>[number] | null>(null)
  const initialValues = {
    recipientName: '',
    transferMethod: '',
    transferAmount: '',
    recipientPhone: '',
    recipientEmail: '',
    message: '',
    occasion: '',
    giftWrap: false,
    giftCard: false,
  };
  const addToMyWallet = async (gifts: number[]) => {
    try {
      setIsLoading(true)
      const response = await DigitalGiftApi.addDigitalGiftToWallet({ digital_gift_ids: gifts }).fetch()
      window.location.replace(`/gift/successful/?sender_name=${response.data.sender_name || ''}&date_time=${response.data.date_time || ''}&amount=${response.data.total_amount || ''}&currency=${response.data.currency || ''}`)
      // setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Error Adding digital gift:', error);
    }
  };
  const isArabic = locale?.startsWith('ar')

  // Transform sending options to select options format

  return (
    <>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 backdrop-brightness-50 z-[58]"
          onClick={onClose}
        />
      </Transition>

      <Transition
        as={Fragment}
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-x-full"
        enterTo="opacity-100 translate-x-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 translate-x-full"
      >
        <div dir={isArabic ? 'rtl' : 'ltr'} className="fixed right-0 top-0 h-full w-screen md:max-w-[643px] bg-[#F9F9F9] z-[59] overflow-y-auto">
          <div className="p-6 h-full flex flex-col">
            <div className="flex gap-2 items-center mb-8 rtl:justify-between rtl:flex-row-reverse">
              <div onClick={() => selectedGift === null ? onClose() : setSelectedGift(null)} className='cursor-pointer'>
                <LeftArrow />
              </div>
              <h1 className="text-lg font-bold text-gray-800 uppercase">
                {selectedGift ? t('Gift Card Details') : t('Gift Cards')}
              </h1>
            </div>
            {selectedGift === null ?
              <div className="flex flex-col gap-4 h-full">
                {digital_gifts?.map((gift, index) => (
                  <div key={index} className="flex items-center justify-between cursor-pointer hover:opacity-90 font-extrabold " onClick={() => setSelectedGift(gift)}>
                    <span className='text-[#7b5e4c] uppercase'>
                      {t('Gift Card from')} {' '}
                      {gift.sender_name}
                    </span>
                    <span className='flex items-center gap-4'>
                      <span className='text-[#4f2f18] '>
                        {gift.amount}
                        {locale?.split('-').includes('en') ? gift.currency_en : gift.currency_ar}
                      </span>
                      <RightChevron />
                    </span>
                  </div>
                ))}

                <Button
                  onClick={() => addToMyWallet(digital_gifts?.map((gift) => !gift.date_time && gift.id).filter(gift => gift !== false) || [])}
                  className="w-full rounded-full mt-auto bg-[#008ec6] font-extrabold uppercase"
                  isDisabled={digital_gifts?.every(g => !!g.date_time) || isLoading}
                >
                  {t('Add all to my wallet')}
                </Button>
              </div>
              :
              <div className='flex flex-col gap-4 h-full'>
                <div className="w-full h-80 text-center">

                  <Image src={selectedGift.image.url} className='my-auto block rounded' alt='gift_image' height={250} width={250} />
                </div>
                <h3 className='font-bold'>{selectedGift.sender_name + " " + t('sent you') + " " + `${locale?.split('-').includes('en') ? selectedGift.currency_en : selectedGift.currency_ar}` + " " + selectedGift.amount}</h3>
                <p>{selectedGift.personalized_message}</p>

                <Button
                  onClick={() => addToMyWallet([selectedGift.id])}
                  isDisabled={!!selectedGift.date_time || isLoading}
                  className="w-full rounded-full mt-auto bg-[#008ec6] font-extrabold uppercase"
                >
                  {t('Add to my wallet')}
                </Button>
              </div>
            }
          </div>
        </div>
      </Transition>
    </>
  );
};

export default GiftRecipientDrawer; 