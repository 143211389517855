import React, { DetailedHTMLProps, TextareaHTMLAttributes } from "react";
import cn from "classnames";
import { useRouter } from "next/router";

interface TextAreaInputProps
  extends Omit<
    DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    "ref"
  > {
  label?: string;
  isRequired?: boolean;
  error?: string;
  placeholder?: string;
  className?: string;
  id?: string;
  inputClassName?: string;
  noLabelPlaceholder?: boolean;
  icon?: React.ReactNode;
  errorClassName?: string;
  positionError?: "top" | "bottom";
}

const TextAreaInput = React.forwardRef<HTMLTextAreaElement, TextAreaInputProps>(
  (
    {
      error = "",
      isRequired = false,
      label = "",
      placeholder,
      className,
      id,
      noLabelPlaceholder = false,
      inputClassName,
      icon = null,
      errorClassName,
      positionError = "bottom",
      ...props
    },
    ref
  ) => {
    const { locale } = useRouter();

    const _id = id || props.name || "input";
    return (
      <div className={cn("flex flex-col w-full", className)}>
        <div className={cn("relative w-full flex-col flex")}>
          <div className="form__group w-full h-[120px] bg-[#F5F5F5] rounded-xl relative">
            <textarea
              id={_id}
              ref={ref}
              placeholder={placeholder}
              className={cn(
                " resize-none form__control border-2 border-[transparent] outline-0 bg-transparent h-full w-full rounded-xl pt-6 pb-2 px-4 text-sm font-normal",
                error ? "err-input" : "",
                locale?.includes("ar") ? "text-right" : ""
              )}
              autoComplete="nope"
              {...props}
            />
            <label
              className={cn(
                "form__label px-4 text-[#999999] text-sm font-normal top-label",
                error ? "err-label" : "",
                locale?.includes("ar") ? "right-0" : "left-0"
              )}
            >
              {label}
            </label>
          </div>

          {positionError === "bottom" && error && (
            <div className={`w-full mt-1 pl-2 flex  ${locale?.includes("ar") ? " justify-end" : "justify-start"} `}>
              <span
                className={cn(
                  "text-red-500 text-xs font-normal",
                  errorClassName
                )}
              >
                {error}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
);

TextAreaInput.displayName = "TextAreaInput";

export default TextAreaInput;
