
import React, { DetailedHTMLProps, InputHTMLAttributes, useState, forwardRef } from "react";
import cn from "classnames";
import { useRouter } from "next/router";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FormikErrors } from "formik";

interface TextInputProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "ref"
  > {
  label?: string;
  isRequired?: boolean;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  placeholder?: string;
  className?: string;
  id?: string;
  inputClassName?: string;
  noLabelPlaceholder?: boolean;
  icon?: React.ReactNode;
  errorClassName?: string;
  positionError?: "top" | "bottom";
  isPassword?: boolean;
}

const usePasswordToggle = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleToggle = () => {
    setShowPassword((prev) => !prev);
  };
  return { showPassword, handleToggle };
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      error = "",
      isRequired = false,
      label = "",
      className,
      id,
      type,
      noLabelPlaceholder = false,
      inputClassName,
      icon = null,
      errorClassName,
      positionError = "bottom",
      isPassword,
      ...props
    },
    ref
  ) => {
    const { locale } = useRouter();
    const { showPassword, handleToggle } = usePasswordToggle();

    const _id = id || props.name || "input";
    const inputType = isPassword ? (showPassword ? "text" : "password") : type;

    return (
      <div className={cn("flex flex-col w-full", className)}>
        <div className={cn("relative w-full flex-col flex")}>
          <div className="form__group w-full h-14 bg-[#F5F5F5] rounded-xl relative">
            <input
              id={_id}
              ref={ref}
              type={inputType}
              className={cn(
                "form__control border-2 border-[transparent] outline-0 bg-transparent h-full w-full rounded-xl pt-3 pb-2 px-4 text-sm font-normal",
                error ? "err-input" : "",
                locale?.includes("ar-") ? "text-right" : ""
              )}
              autoComplete="nope"
              {...props}
            />
            <label
              className={cn(
                "form__label px-4 text-[#999999] text-sm font-normal",
                error ? "err-label" : "",
                props.value ? "top-label" : "",
                locale?.includes("ar") ? "right-0" : "left-0"
              )}
            >
              {label}
            </label>
            {isPassword ? (
              <div
                className={` absolute ${locale?.includes("ar") ? "left-2" : "right-2"
                  } top-[18px] z-10 cursor-pointer`}
              >
                {!showPassword ? (
                  <BsEye
                    size={20}
                    color="#999"
                    onClick={() => handleToggle()}
                  />
                ) : (
                  <BsEyeSlash
                    size={20}
                    color="#999"
                    onClick={() => handleToggle()}
                  />
                )}
              </div>
            ) : null}
          </div>

          {positionError === "bottom" && error && (
            <div className={`w-full mt-1 pl-2 flex  ${locale?.includes("ar-") ? "justify-end" : "justify-start"} `}>
              <span
                className={cn(
                  "text-red-500 text-xs font-normal",
                  errorClassName
                )}
              >
                {error}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
);

TextInput.displayName = "TextInput";

export default TextInput;

