import { useState } from 'react';
import { Listbox } from '@headlessui/react';
import { useField } from 'formik';
import cn from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/solid';
interface Option {
    id: number | string;
    name: string;
    unavailable?: boolean;
}
interface SelectProps {
    name: string;
    label?: string;
    options: Option[];
    placeholder?: string;
    onChange?: (value: string) => void;
    className?: string;
    inputClassName?: string;
    optionsClassName?: string;
}
const Select = ({ label, name, onChange, options, className, ...props }: SelectProps) => {
    const [field, meta, helpers] = useField(name);

    const handleChange = (value: string) => {
        helpers.setValue(value);
        onChange?.(value)
    };

    return (
        <div className={cn('flex flex-col w-full relative')}>
            {label && <label className="text-sm font-medium mb-1">{label}</label>}
            <Listbox value={field.value} onChange={handleChange}>
                <Listbox.Button className={cn(
                    'w-full border rounded-lg p-4 text-sm focus:outline-none',
                    meta.touched && meta.error ? 'border-red-500' : 'border-gray-300', field.value ? '' : 'text-gray-500', className
                )}>
                    <div className="flex justify-between"><span>
                        {options.find(option => option.id === field.value)?.name || props.placeholder || 'select an option'}
                    </span>
                        <span className=" flex items-center pr-2 pointer-events-none">
                            <ChevronDownIcon
                                className="w-5 h-5 "
                                aria-hidden="true"
                            />
                        </span>
                    </div>
                </Listbox.Button>
                <Listbox.Options className="mt-1 w-full bg-white border rounded-md shadow-lg max-h-60 overflow-auto z-10 absolute top-12">
                    {options.map((option) => (
                        <Listbox.Option
                            key={option.id}
                            value={option.id}
                            disabled={option.unavailable}
                            className={({ active, selected }) => cn('cursor-pointer select-none py-4 px-4', {
                                'bg-blue-50': active,
                                'font-semibold bg-blue-100 ': selected,
                                'text-gray-400': option.unavailable,
                            })
                            }
                        >
                            {option.name}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
            {meta.touched && meta.error && <p className="text-xs text-red-500 mt-1">{meta.error}</p>}
        </div>
    );
};

export default Select;
