import { IEntityInput, IHomeItemInput } from "@types";
import Link from "next/link";
import { useRouter } from "next/router";
import useParentSize from "@hooks/useParentSize";
import ImagePrepare from "@elements/imagePrepare";
import { useKeenSlider } from "keen-slider/react"
import { useState } from "react";
import { linkGenerator } from "@utils/linkGenerator";
import { actionGenerator } from "@utils/actionGenerator";
import useBrandView from "@hooks/useBrandViewed";
import useCollectionViewed from "@hooks/useCollectionViewed";
import useAllCategoriesViewed from "@hooks/useAllCategoriesViewed";
import useCategoryViewed from "@hooks/useCategoryViewed";
import useBannerViewed from "@hooks/useBannerViewed";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

interface CarouselProps {
  data: IHomeItemInput;
  sourceEntity?: IEntityInput;

}

const Carousel: React.FC<CarouselProps> = ({ data, sourceEntity }) => {
  const { locale } = useRouter()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      loop: data.items.length > 1 ? true : false,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel)
      },
      created() {
        setLoaded(true)
      },
      mode: "free-snap",
      renderMode: "performance",
      rtl: locale?.includes("en-") ? false : true,
    },
    [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 5000)
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      },
    ]
  )

  const [size, parentRef] = useParentSize()
  const { brandViewed } = useBrandView()
  const { collectionViewed } = useCollectionViewed()
  const { allCategoriesViewed } = useAllCategoriesViewed()
  const { categoryViewed } = useCategoryViewed()
  const { bannerViewed } = useBannerViewed()
  return (
    <div className="w-full max-w-[1240px] m-auto rounded-2xl mt-10 mb-16 keen-slider-container" ref={parentRef}>
      <div ref={sliderRef} className="keen-slider">
        {data.items.map((item) => {
          let target;
          let rel;

          if (item.action.includes("openURL")) {
            if (item.action.includes("openURLinApp")) {
              target = "_self";
              rel = "";
            } else {
              target = "_blank";
              rel = "noopener noreferrer";
            }
          } else {
            target = "_self";
            rel = "";
          }
          const source = {
            from_page: "homepage",
            from_entity: "home",
            from_entity_slug: sourceEntity?.slug || "",
            from_page_section: "",
            from_page_section_entity: "slider",
            from_page_section_slug: item.slug,
          }
          return (
            <div key={item.id} className="keen-slider__slide">
              <div className="relative rounded-2xl overflow-hidden">
                <Link href={linkGenerator(item.action, { source: "homepage", sourceEntity: "home", sourceSlug: sourceEntity?.slug, section: "", sectionEntity: "slider", sectionSlug: item.slug, target_slug: item.target?.slug || "" })} passHref prefetch={false}>
                  <a
                    target={target}
                    rel={rel}
                    onClick={() => {
                      bannerViewed(source, { id: item.id, slug: item.slug }, { id: item.target?.id, slug: item.target?.slug }, item.action)
                      if (actionGenerator(item.action) === "Category") {
                        categoryViewed(source, { id: item.target?.id, slug: item.target?.slug })
                      }

                      if (actionGenerator(item.action) === "Categories List") {
                        allCategoriesViewed(source)
                      }
                      if (actionGenerator(item.action) === "Collection") {
                        collectionViewed(source, { id: item.target?.id, slug: item.target?.slug })
                      }
                      if (actionGenerator(item.action) === "Brand") {
                        brandViewed(source, { id: item.target?.id, slug: item.target?.slug })
                      }
                    }
                    }
                  >
                    <div className="w-full">
                      <div className="w-full lg:pt-[40%] md:pt-[45%] pt-[50%] relative">
                        <div className="w-full h-full inset-0 absolute">
                          <div className="w-full h-full relative rounded-2xl overflow-hidden z-[1]">
                            <ImagePrepare media={item.website_media || item.media} alt={item.name} width={size.width} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </Link>
              </div>
            </div>
          )
        })}
        {
          loaded && instanceRef.current && (data.items.length > 1) && (
            <div className="absolute top-1/2 flex justify-between w-full px-4">
              <button onClick={() => instanceRef.current?.prev()} className="bg-black bg-opacity-40 w-auto p-4 text-white -mt-5 hidden md:block">
                { locale?.includes("en-")?<ChevronLeftIcon className="w-6 h-6" />:<ChevronRightIcon className="w-6 h-6" />}
              </button>
              <button onClick={() => instanceRef.current?.next()} className="bg-black bg-opacity-40 w-auto p-4 text-white -mt-5 hidden md:block">
                {locale?.includes("en-")?<ChevronRightIcon className="w-6 h-6" />:<ChevronLeftIcon className="w-6 h-6" />}
              </button>
            </div>
          )
        }
      </div >
      {loaded && instanceRef.current &&
        (<div className="dots">
          {
            Array.from({ length: data.items.length }, (_, idx) => (
              <button
                key={idx}
                onClick={() => instanceRef.current?.moveToIdx(idx)}
                className={`dot${currentSlide === idx ? " active" : ""}`}
              />
            ))
          }
        </div>
        )}
    </div >
  );
};

export default Carousel;
